import React, { useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import question from '../../../assets/svg/card/circle-question-regular.svg';
import chevron from '../../../assets/svg/nav/chevron-down-solid.svg';

export default function ApplicantCard({ richTxt, heading, buttons, date }) {
	const [hovered, setHovered] = useState(false);
	const [isLiVisible, setIsLiVisible] = useState(false);

	const handleMouseEnter = () => {
		setHovered(true);
	};

	const handleMouseLeave = () => {
		setHovered(false);
	};

	const handleUlClick = () => {
		setIsLiVisible(!isLiVisible);
	};

	return (
		<ul
			className="bg-white rounded-xl	shadow-md"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div
				className="flex flex-row items-center gap-4 px-12 py-8 max-sm:px-6 max-sm:py-8 cursor-pointer select-none"
				onClick={handleUlClick}
			>
				<img src={question} alt="Question mark" className="w-8" />
				<h3
					className={`${hovered ? 'text-cyan duration-150' : ''}${
						isLiVisible ? 'text-cyan' : ''
					}`}
				>
					{heading}
				</h3>
				<img
					src={chevron}
					alt="Chevron"
					className={`w-8 ml-auto duration-150 ${
						isLiVisible ? 'rotate-180' : ''
					}`}
				/>
			</div>

			<li
				className={`border-t-2 py-8 px-12 max-sm:px-6 flex flex-col gap-4 ${
					isLiVisible ? '' : 'hidden'
				}`}
			>
				{documentToReactComponents(richTxt)}
				{buttons && (
					<div className="flex gap-4 flex-wrap">
						{buttons.map((button, index) => (
							<a
								key={index}
								href={button}
								target="_blank"
								rel="noopener noreferrer"
								className="px-4 py-2 w-fit bg-cyan text-white rounded-lg shadow-md font-bold tracking-wider hover:bg-green duration-150 max-sm:w-full max-sm:text-center"
							>
								{button.fields.title}
							</a>
						))}
					</div>
				)}

				{date && <p className="font-bold">{date}</p>}
			</li>
		</ul>
	);
}
